import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  CircularProgress
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TrackingContext } from "../../context";
import { useForm } from "../../hooks";
import {
  Assicurazione,
  Contrassegno,
  DataRichiesta,
  Destinatario,
  Mittente,
  DettaglioMerce,
  Epal,
  MerceADR,
  Servizi,
  TotaleDatiMerce,
  Resa,
} from "./ArgomentiSpedizioniForm";
import { green } from '@mui/material/colors';


const SpedizioniMainForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = React.useState("Nuova spedizione");
  const [salvataggioInCorso, setSalvataggioInCorso] = useState(false);
  const handleClose = () => {
    navigate(-1);
  };

  const {
    state: { 
      configSped,
      formAuto,
      servizioAuto,
      MittenteDefault,
      DestinatarioDefault,
    },
    getConfigModeSpedizioni,
    postSped,
  } = useContext(TrackingContext);



  useEffect(() => {
    setValues({ ...addSpedizioniForm, ServizioPerWeb: configSped.servizio?.default })
  }, [configSped])

  const {
    values: addSpedizioniForm,
    setValues,
    
  } = useForm(
    {
      UploadFile: [],
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCAP: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],

      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],

      DataRichiesta: null,

      ServizioPerWeb: "",
      spedmerce: [],
      Tipo: " ",
      Peso: "",
      Volume: "",
      Quantita: "",
      Epal: null,
      tipoMerce:"",

      NotaDimensioniMerce: "",
      spedadr: [],
      TipoIncasso: " ",
      Contrassegno: "",
      ContrassegnoValuta: "EUR",
      
      ValMerce: "",
      ValMerceValuta: "EUR",
      Resa: "",
      ResaPerWeb:"",
      sceltaContrassegno: false,
      sceltaADR: false,
      sceltaAssicurazione: false,
    },
    []
  );

  useEffect(() => {
    getConfigModeSpedizioni();

  }, [])

  


  return (
    <div>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <Button
              color="warning"
              size={"small"}
              variant={"contained"}
              onClick={handleClose}
              disabled={salvataggioInCorso}
            >
              annulla
            </Button>
            <Box sx={{position:'relative'}}>
            <Button
              color="success"
              size={"small"}
              variant={"contained"}
              onClick={() => postSped(addSpedizioniForm, handleClose, navigate, setSalvataggioInCorso)}
              disabled={salvataggioInCorso}
            >
              salva
            </Button>
            {              
              salvataggioInCorso && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
              )}  
              </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box pt={9} pb={1}>
        {configSped.resa?.visible ? (
          <Resa
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
            vociResa={configSped.resa?.values}            
          />
        ) : (
          <></>
        )}

      {configSped.mittente?.visible ? (
          <Mittente
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
            riferimentoCaption={configSped.mittente?.riferimento?.caption}
            riferimentoVisible={configSped.mittente?.riferimento?.visible}
            luogoRitiroVisible={configSped.mittente?.default_LuogoRitiro}
            readOnly={configSped.mittente?.readonly}
            indicatoriConfig={configSped.mittenteindicatori}
            formAutomatico={formAuto}
            indirizzoCodificatoDefault={MittenteDefault}
            accessoriSubtitle={configSped.mittente.indicatori.subtitle}

          />
        ) : (
          <></>
        )}
        {configSped.destinatario?.visible ? (
          <Destinatario
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
            riferimentoCaption={configSped.destinatario?.riferimento?.caption}
            riferimentoVisible={configSped.destinatario?.riferimento_visible}
            luogoRitiroVisible={configSped.destinatario?.default_LuogoRitiro}
            readOnly={configSped.destinatario?.readonly}
            indicatoriConfig={configSped.destinatarioindicatori}
            formAutomatico={formAuto}
            indirizzoCodificatoDefault={DestinatarioDefault}
            accessoriSubtitle={configSped.destinatario.indicatori.subtitle}
          />
        ) : (
          <></>
        )}
        {configSped.datarichiesta?.visible ? (
          <DataRichiesta setMainForm={setValues} mainForm={addSpedizioniForm} caption={configSped.datarichiesta.caption} subtitle={configSped.datarichiesta.subtitle}/>
        ) : (
          <></>
        )}
        {configSped?.servizio?.visible ? (
          <Servizi setMainForm={setValues} mainForm={addSpedizioniForm} servizioDefault={configSped.servizio?.default} servizioAutomatico={servizioAuto}/>
        ) : (
          <></>
        )}
        {configSped.dettagliomisuremerce?.visible ? (
          <DettaglioMerce
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
            formAutomatico={formAuto}
            TipoMerceList={configSped.tipomerce}
          />
        ) : (
          <></>
        )}
        {configSped.totalemerce?.visible ? (
          <TotaleDatiMerce
            setMainForm={setValues}
            mainForm={addSpedizioniForm}
          />
        ) : (
          <></>
        )}

        <Epal setMainForm={setValues} mainForm={addSpedizioniForm} subtitle={"* Servizio a pagamento"} />

        {configSped.contrassegno?.visible ? (
          <Contrassegno setMainForm={setValues} mainForm={addSpedizioniForm} formAutomatico={formAuto} subtitle={configSped.contrassegno.subtitle}  />
        ) : (
          <></>
        )}
        {configSped.merceadr?.visible ? (
          <MerceADR setMainForm={setValues} mainForm={addSpedizioniForm} formAutomatico={formAuto} />
        ) : (
          <></>
        )}
        {configSped.assicurazione?.visible ? (
          <Assicurazione setMainForm={setValues} mainForm={addSpedizioniForm} title={configSped.assicurazione?.title} subtitle={configSped.assicurazione.subtitle} />
        ) : (
          <></>
        )}
        <Stack direction={"row-reverse"} spacing={2} mt={2}>
          <Box sx={{position:'relative'}}>
            <Button
              color="success"
              size={"medium"}
              variant={"contained"}
              disabled={salvataggioInCorso}
              onClick={() => postSped(addSpedizioniForm, handleClose, navigate, setSalvataggioInCorso)}
            >
              salva
            </Button>
            {
              salvataggioInCorso && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
            )}  
           </Box>
          <Button
            color="warning"
            size={"medium"}
            variant={"contained"}
            onClick={handleClose}
            disabled={salvataggioInCorso}
          >
            annulla
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default SpedizioniMainForm;
